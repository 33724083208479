.App {
    font-family: sans-serif;
    text-align: center;
}
.container {
    width: 100%;
    margin: 0 auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
h2,
h3 {
    font-weight: normal;
}
h2 {
    margin: 0;
}
h3 {
    margin-top: 10px;
}
.reload {
    margin: 10px 0 20px;
    font-size: 12px;
    border: solid 1px #c00;
    padding: 4px 8px;
}


.form {
    display: flex;
}

.form > div {
    display: inline-block;
    vertical-align: top;
}

#treeviewDriveC,
#treeviewDriveD {
    margin-top: 10px;
}

.drive-header {
    min-height: auto;
    padding: 0;
    cursor: default;
}

.drive-panel {
    padding: 20px 30px;
    font-size: 115%;
    font-weight: bold;
    border-right: 1px solid rgba(165, 165, 165, 0.4);
    height: 100%;
}

.drive-panel:last-of-type {
    border-right: none;
}

